import React, { useState, useEffect } from 'react'

import axios from '../../api/axios'
import { useSelector, useDispatch } from 'react-redux'
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

import { setToken } from '../../actions/token'
import { getCurrentUserRoles, setCurrentUser } from '../../actions/currentUser'
import { setLoggedIn } from '../../actions/loggedIn'
import { useNavigate } from 'react-router'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

const Alert = (props) => {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

export default function Login() {
  const navigate = useNavigate()
  const routeChange = (path) => {
    navigate(path)
  }
  //Snack Bar setup
  const [open, setOpen] = useState(false)
  const [severity, setSeverity] = useState('')
  const [alertMsg, setAlertMsg] = useState('')
  const showAlert = (message, severity) => {
    setAlertMsg(message)
    setSeverity(severity)
    setOpen(true)
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  //Context Variables
  const dispatch = useDispatch()
  const loggedIn = useSelector((state) => state.loggedIn)

  const [submitting, setSubmitting] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  //Password UI Settings
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const login = async (e) => {
    try {
      e.preventDefault()
      setSubmitting(true)
      if (username.length === 0) {
        showAlert('Username not provided', 'error')
        return false
      }
      if (password.length === 0) {
        showAlert('Password not provided', 'error')
        return false
      }
      const userCredentials = {
        username: username.trim(),
        password: password.trim(),
      }
      await axios
        .post('/user/login', { ...userCredentials })
        .then((res) => {
          if (res && res.data) {
            if (res.data.error) {
              setAlertMsg(res.data.message)
              showAlert(res.data.message, 'error')
            } else {
              showAlert('login successful', 'success')
              dispatch(setToken(res.data.token))
              localStorage.setItem('apmislltk', res.data.token)
              dispatch(setLoggedIn(true))
            }
            routeChange(`/`)
          }
        })
        .catch((error) => {
          showAlert(error.message, 'error')
        })
    } catch (error) {
      showAlert(error, 'error')
    } finally {
      setSubmitting(false)
    }
  }

  const checkLogin = () => {
    let token = localStorage.getItem('apmislltk')
    if (token !== null) {
      dispatch(setToken(token))
      axios
        .post('user/authenticate')
        .then((res) => {
          if (res.data.auth) {
            dispatch(setLoggedIn(true))
            dispatch(setCurrentUser(res.data.userData.user))
            dispatch(getCurrentUserRoles(res.data.userData.user.id))
          } else {
            dispatch(setLoggedIn(false))
          }
        })
        .catch((err) => {
          dispatch(setLoggedIn(false))
        })
    }
  }

  const [forgotPassword, setForgotPassword] = useState(false)
  const [email, setEmail] = useState('')
  const emailRegExp = /^[a-zA-Z0-9\.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/
  const [selectedUser, setSelectedUser] = useState([])
  const resetPassword = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    if (email.trim().length === 0) {
      showAlert('Email address not provided.', 'error')
      setSubmitting(false)
      return
    }
    if (!emailRegExp.test(email)) {
      showAlert('Email address provided is not valid.', 'error')
      setSubmitting(false)
      return
    }
    await axios
      .get(`user/email/${email.trim().toLowerCase()}`)
      .then((res) => {
        setSelectedUser(res.data)
      })
      .catch((error) => {
        showAlert(error.message, 'error')
        setSubmitting(false)
        return
      })
  }

  const newPassword = async () => {
    await axios
      .post('user/rstpwd', selectedUser[0])
      .then((res) => {
        if (res.data.error === true) {
          showAlert(res.data.message, 'error')
          setSubmitting(false)
          return
        } else {
          setEmail('')
          showAlert(
            `${res.data.message} Please check your email for your new password then proceed to login.`,
            'success'
          )
          setSubmitting(false)
        }
      })
      .catch((error) => {
        showAlert(error.message, 'error')
        setSubmitting(false)
        return
      })
  }

  useEffect(() => {
    checkLogin()
  }, [loggedIn])

  useEffect(() => {
    if (forgotPassword === true) {
      if (selectedUser.length > 0) {
        if (selectedUser[0].status === 'disabled') {
          setSubmitting(false)
          showAlert(
            'Your account was disabled. Please contact the system administrator.',
            'error'
          )
        } else {
          newPassword()
        }
      } else {
        showAlert(
          'Email address provided does not belong to any user.',
          'error'
        )
      }
    }
  }, [selectedUser])

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <div>
          <Alert onClose={handleClose} severity={severity}>
            {alertMsg}
          </Alert>
        </div>
      </Snackbar>
      <form className='form' onSubmit={login}>
        <AccountCircleIcon color='primary' sx={{ fontSize: 100 }} />
        <div className='form-title'>
          <font style={{ color: '#9b0000' }}>AroPMIS</font> |{' '}
          {forgotPassword === false ? <>Sign In</> : <>Reset Password</>}
        </div>
        {forgotPassword === false ? (
          <>
            <TextField
              required
              id='username'
              label='Username'
              value={username}
              defaultValue=''
              margin='normal'
              fullWidth
              onChange={(e) => {
                setUsername(e.target.value)
              }}
            />
            <FormControl variant='outlined' fullWidth sx={{ marginTop: 1 }}>
              <InputLabel htmlFor='outlined-adornment-current-password'>
                Password
              </InputLabel>
              <OutlinedInput
                required
                id='password'
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={(e) => {
                        e.preventDefault()
                      }}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Password'
              />
            </FormControl>
            <Button
              type='submit'
              disabled={submitting}
              sx={{
                textTransform: 'none',
                fontSize: '18px',
                marginTop: '20px',
              }}
              variant='contained'
              size='large'
              fullWidth
              // onClick={(e) => {
              //   login(e)
              // }}
            >
              Sign In
            </Button>
            <div
              style={{
                marginTop: '10px',
                color: 'grey',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                setForgotPassword(true)
              }}
            >
              forgot password
            </div>
          </>
        ) : (
          <>
            <TextField
              required
              id='email'
              label='email'
              defaultValue=''
              margin='normal'
              value={email}
              fullWidth
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
            <Button
              type='button'
              disabled={submitting}
              sx={{
                textTransform: 'none',
                fontSize: '18px',
                marginTop: '20px',
              }}
              variant='contained'
              size='large'
              fullWidth
              onClick={(e) => {
                resetPassword(e)
              }}
            >
              Reset Password
            </Button>
            <div
              style={{
                marginTop: '10px',
                color: 'grey',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                setForgotPassword(false)
              }}
            >
              return to login
            </div>
          </>
        )}
      </form>
    </>
  )
}
