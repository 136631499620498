import * as api from '../api'

export const getRoles = () => async (dispatch) => {
  try {
    const { data } = await api.fetchRoles()
    dispatch({ type: 'FETCH_ROLES', payload: data })
  } catch (error) {
    dispatch({
      type: 'API_ERROR',
      payload: error,
    })
  }
}
