import DashboardIcon from '../../images/dashboard.png'
import Apartment from '../../images/apartment.png'
import TenantRegister from '../../images/tenant-register.png'
import Tenancy from '../../images/tenancy.png'
import NoticeBoard from '../../images/noticeboard.png'
import Incident from '../../images/incident.png'
import Plumber from '../../images/plumber.png'
import Receipt from '../../images/receipt.png'
import Expense from '../../images/expense.png'
import Reports from '../../images/reports.png'

export const NavItems = [
  {
    title: 'Dashboard',
    icon: <img src={DashboardIcon} alt='' style={{ height: 30 }} />,
    link: '/',
  },
  {
    title: 'Properties',
    icon: <img src={Apartment} alt='' style={{ height: 30 }} />,
    link: '/properties',
    // items: [
    //   {
    //     title: 'Properties',
    //     icon: <ApartmentOutlined style={{ color: 'white' }} />,
    //     link: '/Properties',
    //   },
    //   {
    //     title: 'Properties',
    //     icon: <ApartmentOutlined style={{ color: 'white' }} />,
    //     link: '/Properties',
    //   },
    //   {
    //     title: 'Properties',
    //     icon: <ApartmentOutlined style={{ color: 'white' }} />,
    //     link: '/Properties',
    //   },
    // ],
  },
  {
    title: 'Tenants Register',
    icon: <img src={TenantRegister} alt='' style={{ height: 28 }} />,
    link: '/tenants',
  },
  {
    title: 'Tenancies',
    icon: <img src={Tenancy} alt='' style={{ height: 28 }} />,
    link: '/tenancies',
  },
  {
    title: 'Notice Boards',
    icon: <img src={NoticeBoard} alt='' style={{ height: 28 }} />,
    link: '/notices',
  },
  {
    title: 'Incidents',
    icon: <img src={Incident} alt='' style={{ height: 28 }} />,
    link: '/incidents',
  },
  {
    title: 'Maintenance Requests',
    icon: <img src={Plumber} alt='' style={{ height: 28 }} />,
    link: '/maintenance',
  },
  {
    title: 'Receipts',
    icon: <img src={Receipt} alt='' style={{ height: 28 }} />,
    link: '/receipts',
  },
  {
    title: 'Expenses',
    icon: <img src={Expense} alt='' style={{ height: 28 }} />,
    link: '/expenses',
  },
  {
    title: 'Reports',
    icon: <img src={Reports} alt='' style={{ height: 28 }} />,
    link: '/reports',
  },
]
