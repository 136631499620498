const caretakers = (caretakers = [], action) => {
  switch (action.type) {
    case 'FETCH_ALL_CARETAKERS':
      return action.payload
    case 'ADD_NEW_CARETAKER':
      return [...caretakers, action.payload]
    default:
      return caretakers
  }
}
export default caretakers
