import * as api from '../api'

//Action Creators
export const getTenants = () => async (dispatch) => {
  try {
    const { data } = await api.fetchTenants()
    dispatch({ type: 'FETCH_TENANTS', payload: data })
  } catch (error) {
    dispatch({
      type: 'API_ERROR',
      payload: error.response.data.message,
    })
  }
}
