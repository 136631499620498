import * as api from '../api'

export const setCurrentUser = (currentUser) => (dispatch) => {
  if (currentUser) dispatch({ type: 'SET_CURRENT_USER', payload: currentUser })
}

export const getCurrentUser = (id) => async (dispatch) => {
  try {
    const { data } = await api.fetchUser(id)
    dispatch({ type: 'FETCH_USER', payload: data })
  } catch (error) {
    dispatch({
      type: 'API_ERROR',
      payload: error.response.data.message,
    })
  }
}

export const getCurrentUserRoles = (id) => async (dispatch) => {
  try {
    const { data } = await api.fetchUserRolesByUserID(id)
    dispatch({ type: 'FETCH_CURRENT_USER_ROLES', payload: data })
  } catch (error) {
    dispatch({
      type: 'API_ERROR',
      payload: error,
    })
  }
}

export const setCurrentUserRoleNames = (roleNames) => (dispatch) => {
  if (roleNames)
    dispatch({ type: 'SET_CURRENT_USER_ROLE_NAMES', payload: roleNames })
}
