import axios from './axios'

//Fetch Requests
export const fetchCaretakers = () => axios.get('caretaker')
export const fetchCompany = () => axios.get('company/1')
export const fetchCounties = () => axios.get(`county`)
export const fetchProperties = () => axios.get('property')
export const fetchAllProperties = () => axios.get('property/all')
export const fetchPropertyTypes = () => axios.get('propertytype')
export const fetchUnits = () => axios.get(`unit`)
export const fetchAllUnits = () => axios.get(`unit/all`)
export const fetchTenantACEntries = () => axios.get('tenantacentry')
export const fetchAllTenancies = () => axios.get('tenancy/all')
export const fetchTenancies = () => axios.get('tenancy')
export const fetchTenants = () => axios.get('tenant')
export const fetchReceipts = () => axios.get('receipt')
export const fetchUser = (id) => axios.get(`user/${id}`)
export const fetchUsers = () => axios.get('user')
export const fetchUserGroups = () => axios.get('usergroup')
export const fetchRoles = () => axios.get('role')
export const fetchUserRoles = () => axios.get('userrole')
export const fetchUserGroupRoles = () => axios.get('usergrouprole')
export const fetchUserRolesByUserID = (id) => axios.get(`userrole/user/${id}`)

//Post requests  -- usable during optimization
export const addCaretaker = (newCaretaker) =>
  axios.post('caretaker', newCaretaker)
