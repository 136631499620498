import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      light: '#d4442b',
      main: '#9b0000',
      dark: '#660000',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#2c2c2c',
      main: '#000000',
      dark: '#000000',
      contrastText: '#ffffff',
    },
    aroWarningYellow: {
      light: '#FAD700',
      main: '#FFA000',
      dark: '#FFA000',
      contrastText: '#000000',
    },
    aroWarningLightYellow: {
      light: '#F7F700',
      main: '#FAD700',
      dark: '#FFA000',
      contrastText: '#000000',
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'black',
          color: 'white',
        },
      },
    },
  },
})
